<template>
  <div class="doctorDetails flex flex-col space-y-8">
    <vs-card class="mainHeader">
      <div slot="header" class="flex justify-end w-full lg:w-auto">
        <!-- <h4>Doctor Details</h4> -->
        <!-- <h4
          style="color: rgba(var(--vs-primary), 1); cursor: pointer;"
          @click="showProfileApprovalView"
          v-if="
            $store.state.AppActiveUser.userType === 'superAdmin' &&
              doctorData.profileUpdated
          "
        >Profile update request. View to approve</h4>

        <h4
          style="color: rgba(var(--vs-primary), 1); cursor: pointer;"
          @click="showProfileApprovalView"
          v-if="
            $store.state.AppActiveUser.userType === 'admin' &&
              doctorData.profileUpdated
          "
        >Profile update request. View to approve</h4>-->
          <strong
            v-if="
              ($store.state.AppActiveUser.userType === 'admin' ||
                $store.state.AppActiveUser.userType === 'superAdmin') &&
              doctorData.profileUpdated
            "
            style="display: block"
            >Profile update request. Review to approve / reject</strong
          >
          <div class="lg:hidden flex items-center space-x-1 w-full">
            <vs-button
              class="flex-1 m-0"
              v-if="
                ($store.state.AppActiveUser.userType === 'admin' ||
                  $store.state.AppActiveUser.userType === 'superAdmin') &&
                doctorData.profileUpdated
              "
              @click="showProfileApprovalView"
              >Review</vs-button
            >
            <vs-button
              :disabled="$isAuRegion() ? !validateForm : false"
              @click="saveData"
              class="flex-1 m-0"
              >Save Details</vs-button
            >
            <!-- <vs-button color="danger" @click="popUpDelete = true" class="m-2">Delete</vs-button> -->
            <vs-button
              v-if="check_has_permission('changeDoctorPassword')"
              @click="resetPasswordHandler"
              class="flex-1 m-0"
              >Change Password</vs-button
            >
          </div>
          <div class="hidden lg:block">
            <div style="display: flex; justify-content: space-between">
              <div>
                <div
                  v-if="
                    ($store.state.AppActiveUser.userType === 'admin' ||
                      $store.state.AppActiveUser.userType === 'superAdmin') &&
                    doctorData.profileUpdated
                  "
                >
                  <vs-button @click="showProfileApprovalView" class="m-2"
                    >Review</vs-button
                  >
                </div>
              </div>

              <div>
                <vs-button
                  :disabled="$isAuRegion() ? !validateForm : false"
                  @click="saveData"
                  class="m-2"
                  >Save Details</vs-button
                >
                <!-- <vs-button color="danger" @click="popUpDelete = true" class="m-2">Delete</vs-button> -->
                <vs-button
                  v-if="check_has_permission('changeDoctorPassword')"
                  @click="resetPasswordHandler"
                  class="m-2"
                  id="changePassword"
                  >Change Password</vs-button
                >
              </div>
            </div>
          </div>
          <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
            <p align="center">Are you sure you want to delete this user?</p>
            <br />
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              vs-w="12"
              vs-mt="6"
            >
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5" align="right">
                  <vs-button
                    size="lg"
                    color="primary"
                    @click="deleteDoctorHandler"
                    >Yes, delete</vs-button
                  >
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-button
                    size="lg"
                    color="danger"
                    @click="popUpDelete = false"
                    >Cancel</vs-button
                  >
                </div>
              </vs-col>
            </vs-row>
          </vs-popup>

      </div>

      <form autocomplete="off" @submit.prevent>
        <div
        id="profileImageUpload"
        class="vs-con-loading__container w-full lg:w-2/12 hidden lg:flex flex-col items-start mb-6 mt-2"
      >
        <div class="flex items-center">
          <div
            class="img-wrapper cursor-pointer"
            v-if="doctorData.profileImage"
            @click="open_in_new_tab(doctorData.profileImage)"
          >
            <img :src="doctorData.profileImage" class="w-full h-full object-contain" alt />
          </div>
          <!-- <vs-avatar
                size="110px"
                :src="doctorData.profileImage"
                v-if="doctorData.profileImage"
              />-->
          <vs-avatar
            class=" cursor-pointer"
            size="110px"
            :src="defaultPP"
            v-else
            @click="open_in_new_tab(defaultPP)"
          />
        </div>
        <label
            class="image-upload block text-primary font-medium text-center text-base mt-2"
          >
          Change Photo
          <input
            type="file"
            accept="image/*"
            @change="uploadImage($event, 'profileImageUpload')"
            id="file-input"
            hidden
          />
        </label>
        </div>
        <div class="w-full lg:w-auto flex-1 grid grid-cols sm:grid-cols-2 gap-6 lg:gap-4 mt-4 lg:mt-0">
            <vs-input
              :success="
                !errors.first('firstName') && doctorData.firstName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('firstName') ? true : false"
              :danger-text="errors.first('firstName')"
              v-validate="'required|max:150'"
              name="firstName"
              data-vv-as="first name"
              label="First Name"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              class="w-full"
              v-model="doctorData.firstName"
            />
            <vs-input
              :success="
                !errors.first('lastName') && doctorData.lastName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('lastName') ? true : false"
              v-validate="'required|max:150'"
              name="lastName"
              data-vv-as="last name"
              label="Last Name"
              class="w-full"
              :danger-text="errors.first('lastName')"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              v-model="doctorData.lastName"
            />
            <div class="relative"> 
              <!-- <label for>Email</label>-->
              <!-- <p style="margin-right: 5px; font-size: 1rem">
                {{ doctorData.email }}
              </p>-->
              
              <vx-tooltip
                v-if="doctorData.emailVerifiedAt"
                text="Verified"
                class="absolute top-0 right-0"
              >
                <feather-icon
                  style="color: rgba(var(--vs-primary), 1) !important"
                  icon="CheckCircleIcon"
                  svgClasses="h-5 w-5"
                  class="cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <vx-tooltip v-else text="Unverified" class="absolute top-0 right-0">
                <feather-icon
                  style="color: rgba(var(--vs-danger), 1) !important"
                  icon="AlertCircleIcon"
                  svgClasses="h-5 w-5"
                  class="cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <VerifyEmail
                @onSuccessfullyVerified="onSuccessfullyVerified"
                v-if="!doctorData.emailVerifiedAt"
                :userId="doctorId"
              />

              <vs-input
                :success="
                  !errors.first('basic.email') && doctorData.email != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.email')"
                v-model="doctorData.email"
              />
            </div>
            <vs-input
              :success="
                !errors.first('contactNumber') && doctorData.contactNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('contactNumber')"
              v-validate="'required'"
              name="contactNumber"
              :danger-text="errors.first('contactNumber')"
              data-vv-as="contact number"
              label="Contact Number"
              class="w-full"
              icon-no-border
              icon="icon icon-phone"
              icon-pack="feather"
              v-model="doctorData.contactNumber"
            />
            <div>
              <label>Date Of Birth</label>
              <datepicker
                format="dd/MM/yyyy"
                v-model="doctorData.dateOfBirth"
              ></datepicker>
              <input
                type="hidden"
                name="dateOfBirth"
                v-model="doctorData.dateOfBirth"
              />
            </div>
            <vs-input
              :success="!errors.first('abn') && doctorData.abn != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('abn') ? true : false"
              :danger-text="errors.first('abn')"
              name="abn"
              data-vv-as="ABN"
              label="ABN (Optional)"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              class="w-full"
              v-model="doctorData.abn"
            />
            <div>
              <label class="vs-input--label">Address</label>
              <div id="geocoder"/>
              <!-- <vs-select
                :danger-text="errors.first('address')"
                :danger="errors.first('address') ? true : false"
                :success="!errors.first('address') && doctorData.address !== ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                data-vv-as="address"
                name="address"
                autocomplete
                v-model="doctorData.address"
                class="w-full"
                id="addressSuggestion"
                @input-change="handleSuburbSearch($event.target.value)"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in addressItems"
                />
              </vs-select> -->
            </div>
            <div>
              <label class="vs-input--label">AP Type</label>
              <vs-select
                :danger="errors.first('apType') ? true : false"
                :success="!errors.first('apType') && doctorData.apType !== ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                data-vv-as="apType"
                name="apType"
                autocomplete
                v-model="doctorData.apType"
                class="w-full"
                id="apTypeSuggestion"
              >
                <vs-select-item key="doctor" value="Doctor" text="Doctor" />
                <vs-select-item
                  key="ap"
                  value="Physician Assistant"
                  text="Physician Assistant"
                />
                <vs-select-item
                  key="np"
                  value="Nurse Practitioner"
                  text="Nurse Practitioner"
                />
              </vs-select>
            </div>
            <vs-input
                name="drCredentials"
                label="Dr Credentials"
                class="w-full"
                icon-no-border
                v-model="doctorData.drCredentials"
            />
            <div v-if="doctorData.apType !== 'Doctor'">
              <label class="vs-input--label">{{this.$isAuRegion() ? 'Authorising Doctor' : 'Supervising Doctor'}}</label>
              <vs-select
                :danger="errors.first('authorizingDoctor') ? true : false"
                :success="
                  !errors.first('authorizingDoctor') &&
                  doctorData.authorizingDoctor !== ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                data-vv-as="authorizingDoctor"
                name="authorizingDoctor"
                autocomplete
                v-model="doctorData.authorizingDoctor"
                class="w-full"
                id="authorizingDoctorSuggestion"
              >
                <vs-select-item
                  :key="index"
                  :value="item._id"
                  :text="item.firstName + ' ' + item.lastName"
                  v-for="(item, index) in doctorsList"
                />
              </vs-select>
          </div>
          <div class="flex items-end justify-between w-full">
          <div
            class="flex w-full"
            v-if="
              ['superAdmin', 'admin'].includes(
                $store.state.AppActiveUser.userType
              )
            "
          >
            <div
              class="w-full"
              v-if="doctorData.apType !== `NP` || doctorData.apType !== `PA`"
            >
              <doctor-type
                labelText="Doctor Type"
                :checkedVal="doctorData.doctorType || 'contract'"
                @changeDoctorType="changeDoctorType"
              />
            </div>
          </div>
          <div class="w-full flex items-center space-x-2 my-3" v-if="adminApprovalVar == true">
            <label for>Admin Approval</label>
            <vs-switch
              @click="changeAdminApproval(adminStatus)"
              v-model="adminStatus"
              class="mt-2 lg:mt-0"
            />
            <div
              v-if="adminApprovalChangeClick"
            >
              <span style="margin-right: 5px">Processing</span>
              <img
                src="../../../../public/loader.gif"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="w-full flex items-center space-x-2 my-3">
            <label for>Disable User</label>
            <vs-switch
              color="danger"
              id="userStatusLoader"
              @click="changeStatus(userStatus)"
              v-model="userStatus"
            />
            <div
              v-if="statusChangedClick"
              class="mt-2 lg:mt-0"
            >
              <span style="margin-right: 5px">Processing</span>

              <img
                src="../../../../public/loader.gif"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
        </div>
        <!--dr contract on call toggle, admin approval, disable account -->
      </form>
    </vs-card>

    <vs-card v-if="region === 'AU'">
      <div slot="header">
        <h4>AHPRA Details</h4>
      </div>
      <div class="grid grid-cols sm:grid-cols-2 gap-6 lg:gap-4">
          <div v-if="doctorData.apType === 'Doctor'">
            <vs-input
              :success="
                !errors.first('prescriberNumber') &&
                doctorData.prescriberNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('prescriberNumber') ? true : false"
              v-validate="'required'"
              name="prescriberNumber"
              :danger-text="errors.first('prescriberNumber')"
              data-vv-as="prescriber number"
              label="Prescriber #"
              class="w-full"
              icon-no-border
              icon="icon icon-briefcase"
              icon-pack="feather"
              v-model="doctorData.prescriberNumber"
            />
          </div>
          <div>
              <vs-input
                :success="
                  !errors.first('ahpraRegistrationNumber') &&
                  doctorData.ahpraRegistrationNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('ahpraRegistrationNumber') ? true : false"
                v-validate="'required'"
                name="ahpraRegistrationNumber"
                :danger-text="errors.first('ahpraRegistrationNumber')"
                data-vv-as="AHPRA Registration Number"
                label="AHPRA #"
                class="w-full"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                v-model="doctorData.ahpraRegistrationNumber"
              />
          </div>
          <div class="w-full">
              <label>AHPRA Expiry</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="doctorData.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('ahpraExiryDate') && doctorData.ahpraExiryDate,
                  'is-danger': errors.has('ahpraExiryDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="doctorData.ahpraExiryDate"
                    class="bg-color-done input-icon-validate vs-input--icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('ahpraExiryDate')"
                    class="bg-color-error input-icon-validate vs-input--icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.80em">
                {{ errors.first("ahpraExiryDate") }}
              </span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="ahpraExiryDate"
                v-validate="'required'"
                v-model="doctorData.ahpraExiryDate"
              />
          </div>
          <div></div>
          <div id="aphraUpload" class="vs-con-loading__container flex w-full">
            <div class="w-full">
              <label>AHPRA Certificate</label>
              <div v-if="doctorData.ahpraCertificate">
                <div
                  class="mb-1"
                  v-if="
                    determineFileType(doctorData.ahpraCertificate) === 'pdf'
                  "
                >
                  <a :href="doctorData.ahpraCertificate" target="_blank" class="text-base"
                    >Click here to view file</a
                  >
                </div>
                <div v-else>
                  <div
                    style="cursor: pointer"
                    class="large-square-img-full-width-wrapper"
                    @click="open_in_new_tab(doctorData.ahpraCertificate)"
                  >
                    <img :src="doctorData.ahpraCertificate" class="img-fluid" />
                  </div>
                  <!-- <img :src="doctorData.ahpraCertificate" width="100%" /> -->
                </div>
              </div>
              <div class="mt-2">
                <label
                  class="image-upload block w-full text-primary text-base text-center rounded-lg border border-solid border-primary py-2"
                >
                  Change Certificate
                  <input
                    type="file"
                    @change="uploadImage($event, 'aphraUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
      </div>
    </vs-card>

    <vs-card v-if="!$isUSRegion()">
      <div slot="header">
        <h4>Insurance Details</h4>
      </div>
      <div class="grid grid-cols sm:grid-cols-2 gap-6 lg:gap-4">
          <vs-input
            :success="
              !errors.first('indemnityInsuranceProvider') &&
              doctorData.indemnityInsuranceProvider != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="
              errors.first('indemnityInsuranceProvider') ? true : false
            "
            v-validate="'required'"
            :danger-text="errors.first('indemnityInsuranceProvider')"
            name="indemnityInsuranceProvider"
            data-vv-as="indeminity insurance provider"
            label="Indemnity Insurance Provider"
            class="w-full"
            icon-no-border
            icon="icon icon-file"
            icon-pack="feather"
            v-model="doctorData.indemnityInsuranceProvider"
          />
          <vs-input
            :success="
              !errors.first('indemnityInsuranceNumber') &&
              doctorData.indemnityInsuranceNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="
              errors.first('indemnityInsuranceNumber') ? true : false
            "
            v-validate="'required'"
            name="indemnityInsuranceNumber"
            :danger-text="errors.first('indemnityInsuranceNumber')"
            data-vv-as="indemnity insurance number"
            label="Indemnity Insurance number"
            class="w-full"
            icon-no-border
            icon="icon icon-file"
            icon-pack="feather"
            v-model="doctorData.indemnityInsuranceNumber"
          />
          <div class="w-full">
            <label>Indemnity Insurance Expiry</label>
            <datepicker
              class="topPlacement"
              format="dd/MM/yyyy"
              :disabled-dates="disabledDates"
              v-model="doctorData.indemnityInsuranceExpiry"
              :input-class="{
                'is-true':
                  !errors.first('indemnityInsuranceExpiry') &&
                  doctorData.indemnityInsuranceExpiry,
                'is-danger': errors.first('indemnityInsuranceExpiry'),
              }"
            >
              <template slot="afterDateInput">
                <span
                  v-if="doctorData.indemnityInsuranceExpiry"
                  class="bg-color-done input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                </span>
                <span
                  v-if="errors.has('indemnityInsuranceExpiry')"
                  class="bg-color-error input-icon-validate vs-input--icon-validate"
                >
                  <i
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </template>
            </datepicker>
            <span class="text-danger" style="font-size: 0.80em">
              {{ errors.first("indemnityInsuranceExpiry") }}
            </span>
          </div>
          <div></div>
          <div
          id="insuranceUpload"
          class="vs-con-loading__container flex w-full"
          >
            <div class="w-full">
              <label>Insurance Certificate</label>
              <div v-if="doctorData.insuranceCertificate">
                <div
                  v-if="
                    determineFileType(doctorData.insuranceCertificate) === 'pdf'
                  "
                >
                  <a :href="doctorData.insuranceCertificate" target="_blank" class="text-base"
                    >Click here to view file</a
                  >
                </div>
                <div v-else>
                  <div
                    class="cursor-pointer"
                    @click="open_in_new_tab(doctorData.insuranceCertificate)"
                    >
                    <img
                    :src="doctorData.insuranceCertificate"
                    class="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <label
                  class="image-upload block w-full text-primary text-base text-center rounded-lg border border-solid border-primary py-2"
                >
                  Change Certificate
                  <input
                    type="file"
                    @change="uploadImage($event, 'insuranceUpload')"
                    id="file-input"
                    hidden
                    />
                  </label>
                </div>
                <span class="text-danger text-sm">
                  {{ errors.first("insuranceCertificate") }}
                </span>
              </div>
          </div>
      </div>
    </vs-card>

    <vs-card>
        <div slot="header">
          <h4>Doctor Roles</h4>
        </div>
        <div>
          <!-- Medical Director -->
          <div v-if="!$isAuRegion()">
            <div class=" flex " >
                <vs-switch
                  id="mdStatus"
                  @click="onDoctorChangeStatus(mdStatus, 'md')"
                  v-model="mdStatus"
                />
              <div>
                <p class="font-semibold text-base mb-1">Medical Director</p>
                <p class="py-2">Associate this Doctor as a Medical Director for clinics. This Doctor will be responsible for overwatching the clinic treatments and supply. </p>
                <div class="py-2">
                  <vs-table
                    ref="table"
                    :sst="true"
                    :data="mdClinics"
                  >
                  <template slot="thead">
                    <vs-th>Clinic Name</vs-th>
                    <vs-th>Clinic State</vs-th>
                    <vs-th>Action</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="tr.clinicName">{{ data[indextr].clinicName | capitalize}}</vs-td>
                      <vs-td :data="tr.state">{{ tr.state | capitalize}}</vs-td>
                    <vs-button class=" my-2"  @click="removeClinicPrompt(tr._id)" type="border">Remove</vs-button>
                    </vs-tr>
                  </template>

                </vs-table>
                </div>
                <div class=" py-1">
                  <vs-button :disabled="!mdStatus" @click="onAddClinic" type="border"> Add Clinic </vs-button>
                </div>
              </div>
            </div>
            <vs-divider/>
          </div>

          <!-- Supervising Doctor -->
          <div v-if="$isUSRegion()">
            <div class=" flex ">
              <div class=" pr-2">
                <vs-switch
                  id="sdStatus"
                  @click="onDoctorChangeStatus(sdStatus, 'sd')"
                  v-model="sdStatus"
                />
              </div>
              <div>
                <p class="font-semibold text-base mb-1">Supervising Doctor</p>
                <p class="py-2">Associate this Doctor as a Supervising doctor for NP/PAs. This Doctor is responsible for oversight on treatments that these NP/PAs complete.</p>
                <div class="py-2">
                  <vs-table
                    ref="table"
                    :sst="true"
                    :data="supervisedUsers"
                  >
                  <template slot="thead">
                    <vs-th sort-key="clinicName">NP/PA Name</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].clinicName">{{ `${data[indextr].firstName} ${data[indextr].lastName}` | capitalize}}</vs-td>
                    </vs-tr>
                  </template>

                </vs-table>
                </div>
                <!-- <div class=" py-1">
                  <vs-button type="border"> Add Clinic </vs-button>
                </div> -->
              </div>
            </div>
            <vs-divider/>
          </div>

          <!-- National Medical Director (Super Doctor) -->
          <div class="flex items-start space-x-4" v-if="$isAuRegion() || $isUSRegion()">
            <vs-switch
              id="isNationalMedicalDirector"
              @click="onDoctorChangeStatus(isNationalMedicalDirector, 'nmd')"
              v-model="isNationalMedicalDirector"
            />
            <div>
              <p class="font-semibold text-base mb-1">National Medical Director (Super Doctor)</p>
              <p>Give this Doctor full access to all patient and treatments within the entire Fresh Clinics system.</p>
            </div>
          </div>
        </div>

    </vs-card>

    <vs-card style="display: none">
      <div slot="header">
        <h4>Signature</h4>
      </div>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col id="signature" class="vs-con-loading__container mt-5" vs-w="4">
          <div class="m-5">
            <label for="signature">Signature</label>
            <div style="width: 100%">
              <img
                style="cursor: pointer"
                @click="open_in_new_tab(doctorData.insuranceCertificate)"
                :src="doctorData.signature"
                width="100%"
                v-if="doctorData.signature"
              />
              <p v-else id="signature">N/A</p>
            </div>
            <div class="mt-1" style="text-align: center; position: relative">
              <label
                class="image-upload"
                style="
                  border: 1px solid #3dc9b3;
                  display: inline-block;
                  width: 100%;
                  border-radius: 25px;
                  padding: 5px;
                "
              >
                Change Signature
                <input
                  type="file"
                  @change="uploadImage($event, 'signature')"
                  accept="image/*"
                  id="file-input"
                  hidden
                />
              </label>
            </div>
          </div>
        </vs-col>

        <!-- <vs-col vs-type="flex" vs-align="left" vs-w="4">
          <div  class="w-full m-5">
            <vs-select v-if="fetchClinicsBy=='franchise'" placeholder="Select Clinic" label="Associated Clinic" multiple autocomplete v-model="clinic" class="w-full" v-validate="'required'" name="selectClinic">
              <vs-select-item :key="index" :value="item._id" :text="item.clinicName ? item.clinicName : item.firstName" v-for="(item,index) in clinics" />
            </vs-select>
            <span class="text-danger" style="font-size: 0.75em">{{ errors.first('selectClinic') }}</span>
          </div>
        </vs-col>-->
      </vs-row>
    </vs-card>
    
    <!-- <div v-if="check_has_permission('viewDoctorAssociatedClinics')">
      <vs-card>
        <template v-slot:header>
          <h4>Associated Clinics</h4>
        </template>
        <associated-clinics :userId="doctorId" />
      </vs-card>
    </div> -->

    <div id="resetPasswordContainer">
      <vs-popup
        class="holamundo"
        title="Reset Password"
        :active.sync="resetPassword"
      >
        <vs-form autocomplete="off">
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  :success="
                    !errors.first('resetPassword.newPassword') && password != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.newPassword') ? true : false
                  "
                  name="newPassword"
                  :danger-text="errors.first('resetPassword.newPassword')"
                  label="New Password"
                  class="w-full"
                  v-validate="{
                    required: true,
                    min: 8,
                    regex:
                      /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                  }"
                  data-vv-as="new password"
                  data-vv-scope="resetPassword"
                  ref="password"
                  icon-no-border
                  icon-pack="feather"
                  v-model="password"
                  :icon="passwordIcon"
                  :type="passwordType"
                  @click.native="showHidePassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  label="Confirm Password"
                  :success="
                    !errors.first('resetPassword.confirmPassword') &&
                    confirmPassword != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.confirmPassword') ? true : false
                  "
                  name="confirmPassword"
                  :danger-text="errors.first('resetPassword.confirmPassword')"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="confirm password"
                  data-vv-scope="resetPassword"
                  class="w-full"
                  icon-no-border
                  icon-pack="feather"
                  v-model="confirmPassword"
                  :icon="confirmPasswordIcon"
                  :type="confirmPasswordType"
                  @click.native="showHideConfirmPassword($event)"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="lg"
                  @click="changePassword"
                  icon-pack="feather"
                  id="confirmResetPassword"
                  class="mr-2"
                  >Reset Password</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  @click="resetPassword = false"
                  color="danger"
                  icon-pack="feather"
                  class="mr-2"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-form>
      </vs-popup>
    </div>
    <!-- <vs-card v-if="check_has_permission('viewDoctorTreatmentHistory')">
      <template v-slot:header>
        <h4>Doctor Treatment History</h4>
      </template>
      <treatment-history></treatment-history>
    </vs-card> -->
    <vs-popup id="removePopUp" title="" :active.sync="popUpRemove">
     <p align="center">Are you sure you want to delete this clinic?</p>
            <br />
            <vs-row
              vs-align="flex-start"
              vs-type="flex"
              vs-justify="left"
              vs-w="12"
              vs-mt="6"
            >
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5" align="right">
                  <vs-button
                    size="lg"
                    color="primary"
                    @click="onClinicRemove"
                    >Yes, delete</vs-button
                  >
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-align="left" vs-w="6">
                <div class="w-full m-5">
                  <vs-button
                    size="lg"
                    color="danger"
                    @click="popUpRemove = false"
                    >Cancel</vs-button
                  >
                </div>
              </vs-col>
            </vs-row>
    </vs-popup>
    <vs-popup title="Assign Clinics" :active.sync="popupActivo">
      <div>
        <vs-select
          autocomplete
          placeholder="Select Clinic"
          label="Clinics"
          id='clinicsSelect'
          v-model="clinicIds"
          class="w-full m-2"
        >
          <vs-select-item
            :key="index"
            :value="item._id"
             :text="item.name"
            v-for="(item, index) in fetchUpdatedClinicList"
          />
        </vs-select>

        <vs-button
          @click="assignClinic"
          color="primary"
          id='confirmRequest'
          class="m-2"
          type="border"
          >Assign</vs-button
        >
      </div>

      <!-- <div v-else>
        <vs-alert color="warning">Further clinics are not available.</vs-alert>
      </div> -->
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios";
import { mapActions } from "vuex";
import treatmentHistory from "./treatmentHistory.vue";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import moment from "moment";
import DoctorType from "./doctorType";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const dict = {
  custom: {
    firstName: {
      required: "Please enter the first name",
    },
    lastName: {
      required: "Please enter the last name",
    },
    email: {
      required: "Please enter the email address",
    },
    contactNumber: {
      required: "Please enter the contact number",
    },
    rate: {
      required: "Please enter the doctor rate",
      regex: "Please enter valid rate for the doctor",
    },
    prescriberNumber: {
      required: "Please enter the prescriber number",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    newPassword: {
      required: "Please enter the password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter the confirm password",
      confirmed: " The confirmation password do not match",
    },
    address: {
      required: "Enter valid address. Must choose from dropdown suggestion.",
    },
  },
};
Validator.localize("en", dict);
export default {
  name: "doctor-edit",
  props: {
    fetchClinicsBy: {
      type: String,
      default: "franchise",
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    adminApprovalVar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    treatmentHistory,
    Datepicker,
    VerifyEmail,
    DoctorType,
  },
  data: () => ({
    addressItems: [],
    defaultPP: require("@/assets/images/profile/default.png"),
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    doctorId: "",
    password: "",
    confirmPassword: "",
    doctorsList: [],
    mdClinics: [
      // {clinicName: "Clinic Name Example A", clinicState: "Texas"},
      // {clinicName: "Clinic Name Example B", clinicState: "Washington"}
    ],
    doctorData: {
      firstName: "",
      lastName: "",
      preferredName: "",
      contactNumber: "",
      authorizingDoctor: "",
      prescriberNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      dateOfBirth: "",
      abn: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      profileImage: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      signature: "",
      status: "",
      hasApprovedByAdmin: "",
      profileUpdated: false,
      apType: "",
      emailVerifiedAt: null,
      email: "",
      rate: "",
      doctorType: "contract",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: { type: "Point", coordinates: [] },
      },
    },
    clinics: [
      // {clinicName: "Clinic A"},
      // {clinicName: "Clinic B"},
    ],
    clinicToRemove:"",
    clinic: "",
    disabledDates: { to: new Date() },
    userStatus: false,
    adminStatus: false,
    popUpDelete: false,
    resetPassword: false,
    checkDisplayAddress: "",
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
    confirmPasswordType: "password",
    confirmPasswordIcon: "icon icon-eye-off",
    loggedInUserType: "",
    region: process.env.VUE_APP_REGION,
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    mdStatus: false,
    sdStatus: false,
    isNationalMedicalDirector: false,
    popupActivo: false,
    clinicIds: "",
    supervisedUsers: [],
    popUpRemove:false,
  }),
  computed: {
    validateForm() {
      let prescriberNumberValidation =
        this.doctorData.prescriberNumber != "" &&
        this.doctorData.prescriberNumber === "Doctor"
          ? false
          : true;
      return (
        !this.errors.any() &&
        this.doctorData.firstName != "" &&
        this.doctorData.lastName != "" &&
        this.doctorData.contactNumber != "" &&
        this.doctorData.ahpraRegistrationNumber != "" &&
        this.doctorData.ahpraExiryDate != "" &&
        this.doctorData.indemnityInsuranceProvider != "" &&
        this.doctorData.indemnityInsuranceNumber != "" &&
        this.doctorData.indemnityInsuranceExpiry != "" &&
        prescriberNumberValidation &&
        this.doctorData.ahpraCertificate != "" &&
        this.doctorData.insuranceCertificate != "" &&
        this.doctorData.rate != "" &&
        this.doctorData.email != "" &&
        this.doctorData.doctorType != ""
      );
    },
    fetchUpdatedClinicList(){
      let clinics = this.clinics.filter((item)=> {
        return !this.mdClinics.some(e => e.clinicId === item._id)
      })

      return clinics
    }
  },
  methods: {
    ...mapActions("doctor", [
      "fetchDoctorDetail",
      "updateDoctorDetail",
      "resetDoctorPassword",
      "deleteDoctor",
      "fetchAuthorizingDoctors",
      "updateDoctorRoles",
      "fetchSupervisedUsers",
      "updateAssignedClinicMedicalDoctor",
      "fetchAssignedMedicalDoctor",
      "removeAssignedClinic",
    ]),
     ...mapActions("clinic",["fetchClinicList"]),
    ...mapActions("franchise", ["fetchClinics"]),
    async onDoctorChangeStatus(status, type) {
      if(type === "md"){
        try{
          const data = {
            doctorId:this.doctorId,
            status: !status,
            doctorRoleType: "md"
          }
          await this.updateDoctorRoles(data);

          this.mdStatus = !status

          this.$vs.notify({
            title: "Success",
            text: "Medical director status updated successfully.",
            color: "success",
          });
        }
        catch(e){
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update medical director status",
            color: "danger",
          });
          return;
        }
      }
      else if(type === "sd"){
        try{
          const data = {
            doctorId:this.doctorId,
            status: !status,
            doctorRoleType: "sd"
          }
          const res = await this.updateDoctorRoles(data);
          if(res.statusText === "OK"){
            this.sdStatus = !status
            this.$vs.notify({
              title: "Success",
              text: "Supervising doctor status updated successfully.",
              color: "success",
            });
          }
          else{
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update supervising doctor status",
              color: "danger",
            });
          }
        }
        catch(e){
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update supervising doctor status",
            color: "danger",
          });
        }
      }
      else if(type === "nmd"){
        try{
          const data = {
            doctorId:this.doctorId,
            status: !status,
            doctorRoleType: "nmd"
          }
          const res = await this.updateDoctorRoles(data);
          if(res.statusText === "OK"){
            this.isNationalMedicalDirector = !status
            this.$vs.notify({
              title: "Success",
              text: "National medical director status updated successfully.",
              color: "success",
            });
          }
          else{
            this.$vs.notify({
              title: "Failed",
              text: "Failed to update national medical director status",
              color: "danger",
            });
          }
        }
        catch(e){
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update national medical director status",
            color: "danger",
          });
        }
      }
    },
    async assignClinic() {
      let payload = {
        doctorId: this.doctorId,
        clinicId: this.clinicIds
        }
        try {
        const res = await this.updateAssignedClinicMedicalDoctor(payload)
        this.getAssignedMedicalDoctor()
        this.clinicIds = ""
        this.popupActivo = false
        } catch (e) {
          console.log(e)
        }

    },
    onAddClinic() {
      this.popupActivo = true
    },
    onClinicRemove() {
     this.removeAssignedClinic(this.clinicToRemove).then((res)=>{
       this.$vs.notify({
          title: "Removed Clinic",
          text: "Clinic Removed Successfully.",
          color: "success",
        });
        this.getAssignedMedicalDoctor()
     }).catch((e)=>{
       console.log(e)
     }).finally(()=>{
        this.popUpRemove = false
     })
    },
    async getSupervisedUsers() {
      try {
        const res = await this.fetchSupervisedUsers({doctorId: this.doctorId})
        this.supervisedUsers = res.data.data
      }
      catch(e){
        return;
      }
    },
    async getAssignedMedicalDoctor(){
      try{
        const res = await this.fetchAssignedMedicalDoctor({doctorId: this.doctorId})
        this.mdClinics = res.data.data
      }
      catch(e){
        console.log(e)
      }
    },
    handleSuburbSearch(searching) {
      if (this.suburbSearchDebounce) {
        clearTimeout(this.suburbSearchDebounce);
        this.suburbSearchDebounce = undefined;
      }
      this.suburbSearchDebounce = setTimeout(
        () => this.autoCompleteFunc(searching),
        500
      );
    },
    changeDoctorType(type) {
      this.doctorData.doctorType = type;
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    autoCompleteFunc(text) {
      if (text.length > 0)
        axios
          .get("/api/v1/getSuburbs", { params: { searchText: text } })
          .then((response) => {
            if (response.data.data.docs.length > 0) {
              const arr = response.data.data.docs.map((singleItem) => {
                singleItem.text = singleItem.displayAddress;
                singleItem.value = singleItem.displayAddress;
                return singleItem;
              });
              this.addressItems = [];
              this.addressItems = arr;
            }
          });
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async saveData() {
      let self = this;
      let isValid = await this.$validator.validateAll("");

      if (this.doctorData.contactNumber == "") {
        isValid = false;
        self.errors.add({
          field: "contactNumber",
          msg: "Please enter the contact number",
        });
      } else {
        if (this.$isAuRegion()) self.errors.remove("contactNumber");
      }
      if (!this.doctorData.address) {
        isValid = false;
        self.errors.add({
          field: "address",
          msg: "Enter valid address. Must choose from dropdown suggestion.",
        });
      } else {
        if (this.$isAuRegion()) self.errors.remove("address");
      }

      if (this.doctorData.ahpraCertificate == "" && this.$isAuRegion()) {
        isValid = false;
        self.errors.add({
          field: "aphraCertificate",
          msg: "APHRA Certificate is required",
        });
      } else {
        if (this.$isAuRegion()) self.errors.remove("aphraCertificate");
      }
      if (this.doctorData.indemnityInsuranceProvider == "" && !this.$isUSRegion()) {
        isValid = false;
        self.errors.add({
          field: "indemnityInsuranceProvider",
          msg: "Please enter valid indemnity insurance name",
          // scope: 'basic'
        });
        this.$vs.notify({
          title: "Failed",
          text: "Please enter valid indemnity insurance name",
          color: "danger",
        })
      } else {
        if (this.$isAuRegion()) {
          self.errors.remove("indemnityInsuranceProvider");
        }
      }

      if (this.doctorData.insuranceCertificate == "" && !this.$isUSRegion()) {
        isValid = false;
        self.errors.add({
          field: "insuranceCertificate",
          msg: "Insurance Certificate is required",
          // scope: 'basic'
        });
        this.$vs.notify({
          title: "Failed",
          text: "Insurance Certificate is required",
          color: "danger",
        })
      } else {
        if (this.$isAuRegion()) {
          self.errors.remove("insuranceCertificate");
        }
      }
      if (this.doctorData.indemnityInsuranceNumber == "" && !this.$isUSRegion()) {
        isValid = false;
        self.errors.add({
          field: "indemnityInsuranceNumber",
          msg: "Please enter valid indemnity insurance number",
          // scope: 'basic'
        });
      } else {
        if (this.$isAuRegion()) {
          self.errors.remove("indemnityInsuranceNumber");
        }
      }

      if (isValid) {
        if (this.adminStatus == true) this.doctorData.hasApprovedByAdmin = true;
        if (this.adminStatus == false)
          this.doctorData.hasApprovedByAdmin = false;

        if (this.userStatus == true) this.doctorData.status = "Disabled";
        if (this.userStatus == false) {
          this.doctorData.status = "Active";
          // this.doctorData.hasApprovedByAdmin = false;
        }

        if (
          this.loggedInUserType === "clinic" ||
          this.loggedInUserType === "subClinic"
        ) {
          let rate = this.doctorData.rate
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/)[0];
          this.doctorData.rate = rate;
        }
        await this.updateDoctorDetail({
          id: this.doctorId,
          data: this.doctorData,
        })
          .then((res) => {
            self.$vs.notify({
              title: "Doctor updated",
              text: "Doctor's details updated successfully.",
              color: "success",
            });
            const user = this.$store.state.AppActiveUser.userRole;
            if (user === "superAdmin") {
              self.$router.push("/super-admin/doctors");
            } else if (user === "admin") {
              self.$router.push("/admin/doctors");
            }
          })
          .catch((err) => {
            isValid = false;
            if (err.status === 422) {
              self.$vs.notify({
                title: "Failed",
                text: "Failed to update doctor. Try again!",
                color: "danger",
              });
              self.mapServerErrors(self, err, "");
            }

            if (err.status === 409) {
              self.errors.add({
                field: "email",
                msg: err.data.message,
              });
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }

            if (err.status === 417) {
              self.errors.add({
                field: "abn",
                msg: err.data.message,
              });
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }

            if (err.status === 420) {
              self.errors.add({
                field: "dateOfBirth",
                msg: err.data.message,
              });
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
            }
          });
      } else {
        console.log(isValid, this.errors);
      }
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        color: "danger",
      });
    },
    onSuccessfullyVerified(verifiedAt) {
      this.doctorData.emailVerifiedAt = verifiedAt;
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetDoctorPassword({
          id: this.doctorId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "Doctor's password reset",
            text: "Doctor's password reset Successfully.",
            color: "success",
          });
        });
      }
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading ...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key + " div div",
      });
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    async getDoctorDetail(doctorId) {
      let self = this;
      await this.fetchDoctorDetail(doctorId)
        .then((res) => {
          if (res.data.data.permissions) {
            this.$store.commit(
              "UPDATE_USER_PERMISSION",
              res.data.data.permissions
            );
          }

          const doctor = res.data.data;
          self.sdStatus = doctor.isSupervisingDirector;
          self.mdStatus = doctor.isMedicalDirector;
          self.isNationalMedicalDirector= doctor.isNationalMedicalDirector;
          self.doctorId = doctor._id;
          self.doctorData = {
            authorizingDoctor: doctor.authorizingDoctor,
            firstName: doctor.firstName,
            lastName: doctor.lastName,
            preferredName: doctor.preferredName,
            contactNumber: doctor.contactNumber,
            prescriberNumber: doctor.prescriberNumber,
            ahpraRegistrationNumber: doctor.ahpraRegistrationNumber,
            apType: doctor.apType,
            ahpraExiryDate: doctor.ahpraExiryDate
              ? self.formatDate(doctor.ahpraExiryDate)
              : "",
            dateOfBirth: doctor.dateOfBirth
              ? self.formatDate(doctor.dateOfBirth)
              : "",
            indemnityInsuranceProvider: doctor.indemnityInsuranceProvider,
            abn: doctor.abn,
            indemnityInsuranceNumber: doctor.indemnityInsuranceNumber,
            indemnityInsuranceExpiry: doctor.indemnityInsuranceExpiry
              ? self.formatDate(doctor.indemnityInsuranceExpiry)
              : "",
            profileImage: doctor.profileImage,
            rate: doctor.rate || "N/A",
            ahpraCertificate: doctor.ahpraCertificate,
            insuranceCertificate: doctor.insuranceCertificate,
            signature: doctor.signature,
            status: doctor.status,
            hasApprovedByAdmin: doctor.hasApprovedByAdmin,
            profileUpdated: doctor.profileUpdated,
            emailVerifiedAt: doctor.emailVerifiedAt,
            email: doctor.email,
            doctorType: doctor.doctorType || "contract",
            address: doctor.address || {},
            drCredentials: doctor.drCredentials
          };


          if (self.doctorData.status == "Active") this.userStatus = false;
          else this.userStatus = true;
          if (self.doctorData.hasApprovedByAdmin == true)
            this.adminStatus = true;
          if (self.doctorData.hasApprovedByAdmin == false)
            this.adminStatus = false;

          return axios.get("/api/v1/getSuburbs");
        })
        .then((response) => {
          if (response && response.data.data.docs.length > 0) {
            const arr = response.data.data.docs.map((singleItem) => {
              singleItem.value = singleItem.displayAddress;
              singleItem.text = singleItem.displayAddress;
              return singleItem;
            });
            this.addressItems = arr;
          }
        });
    },
    formatDate(date) {
      var d = new Date(date,'YYYY-M-D');
      if (isNaN(d.getTime())) {
        var momnetDate = moment(date, 'YYYY-M-D'),
        month = "" + (momnetDate.month() + 1), // In moment.js, months are 0-indexed
        day = "" + momnetDate.date(),
        year = momnetDate.year();
      } else {
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      }
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };

      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.doctorData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            self.doctorData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            self.doctorData.insuranceCertificate = res.data.Location;
          }
          if (key === "signature") {
            self.doctorData.signature = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    deleteDoctorHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      this.popUpDelete = false;
      let id = this.doctorId;
      this.deleteDoctor(id).then((res) => {
        this.$vs.notify({
          subject: "Deleted Doctor",
          text: "Doctor deleted Successfully.",
          color: "success",
        });
        if (user === "superAdmin") {
          this.$router.push("/super-admin/doctors");
        } else {
          this.$router.push("/" + user + "/doctors");
        }
      });
    },
    removeClinicPrompt(e){
      this.popUpRemove=true
      this.clinicToRemove = e
    },
    resetPasswordHandler() {
      this.resetPassword = true;
      document.getElementById("resetPasswordContainer").scrollIntoView();
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
          scope: form,
        });
      });
    },
    showProfileApprovalView() {
      let loggedInUserType =
        this.$store.state.AppActiveUser.userType === "superAdmin"
          ? "super-admin"
          : "admin";
      this.$router.push(
        `/${loggedInUserType}/doctors/${this.$route.params.doctorId}/profile-update-review`
      );
    },
    place_changed(a) {},
    async changeStatus(status) {
      this.statusChangedClick = true;
      let us = "Active";
      if (!status) us = "Disabled";

      let updateData = {
        status: us,
      };

      // if (us === "Active" && !this.doctorData.emailVerifiedAt) {
      //   updateData.emailVerifiedAt = new Date().toISOString();
      // }
      await this.updateDoctorDetail({
        id: this.doctorId,
        data: updateData,
      })
        .then((res) => {
          // this.doctorData.emailVerifiedAt = res.data.data.emailVerifiedAt;
          this.$vs.notify({
            title: "Success",
            text: "Status updated successfully.",
            color: "success",
          });
          this.statusChangedClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update status. Try again!",
            color: "danger",
          });
          this.statusChangedClick = false;
        });
    },
    async changeAdminApproval(status) {
      this.adminApprovalChangeClick = true;
      await this.updateDoctorDetail({
        id: this.doctorId,
        data: { hasApprovedByAdmin: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Admin approval updated successfully.",
            color: "success",
          });
          this.adminApprovalChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update admin approval. Try again!",
            color: "danger",
          });
          this.adminApprovalChangeClick = false;
        });
    },
    regionType() {
      const regions = {
        US: "us",
        AU: "au",
        NZ: "nz"
      }
      return regions[this.region]
    }
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
    this.loggedInUserType = this.$store.state.AppActiveUser.userType;
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: this.regionType() } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.addressData = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.doctorData.address.displayAddress = place.formatted_address;

      this.doctorData.address.geometry = { type: 'Point', coordinates: [lon, lat]}
      // this.doctorData.address.geometry.coordinates[0] = lon;
      // this.doctorData.address.geometry.coordinates[1] = lat;

      this.doctorData.address.suburb = "";
      this.doctorData.address.state = "";
      this.doctorData.address.postCode = "";
      if (
        place &&
        place.address_components &&
        place.address_components.length > 0
      ) {
        place.address_components.forEach(function (gAdd) {
          if (gAdd.types[0] === "administrative_area_level_2") {
            this.doctorData.address.suburb = gAdd.long_name;
          }
          if (gAdd.types[0] === "administrative_area_level_1") {
            this.doctorData.address.state = gAdd.long_name;
          }
          if (gAdd.types[0] === "postal_code") {
            this.doctorData.address.postCode = gAdd.long_name;
          }
        });
      }
    });
  },
  async created() {
    this.addressItems = [];
    let doctorId = this.$route.params.doctorId;
    this.doctorId = doctorId;

    await this.getDoctorDetail(doctorId);
   await  this.getSupervisedUsers();
    await this.getAssignedMedicalDoctor();
    this.fetchClinicList()
    .then((res)=>{
      this.clinics = res.data.data
      let temp = res.data.data.filter((f)=>{
        return f.state
      })
    })
    .catch((err)=>console.log("Error fetching clinic list",err))
    this.fetchAuthorizingDoctors()
      .then((res) => {
        this.doctorsList = res.data.data;
        let filterDoctorsListByAuthorizingDoctor = [];

        if (
          this.doctorsList &&
          Array.isArray(this.doctorsList) &&
          this.doctorData.authorizingDoctor
        ) {
          filterDoctorsListByAuthorizingDoctor = this.doctorsList.filter(
            (item) => {
              return (
                item._id.toString() ===
                this.doctorData.authorizingDoctor.toString()
              );
            }
          );
        }
        let authorizingDoctor;
        if (filterDoctorsListByAuthorizingDoctor.length) {
          authorizingDoctor = filterDoctorsListByAuthorizingDoctor[0]._id;
        }
        this.doctorData.authorizingDoctor = authorizingDoctor;
      })
      .catch((err) => console.log("Error fetching authorize doctors :::", err));

    mapboxgl.accessToken = this.accessToken;
      var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: 'address',
      countries: this.regionType(),
      placeholder: this.doctorData.address.displayAddress
    });

    geocoder.addTo('#geocoder');
    geocoder.on('result', (e) => {
      this.doctorData.address.suburb = e.result.context.filter(item=>item.id.includes( this.region === 'US' ? 'district' : 'locality'))[0].text
      this.doctorData.address.state = e.result.context.filter(item=>item.id.includes("region"))[0]["text_en-US"]
      this.doctorData.address.postCode = e.result.context.filter(item=>item.id.includes("postcode"))[0].text
      this.doctorData.address.displayAddress = e.result.place_name
      this.doctorData.address.geometry = { type: 'Point', coordinates: [e.result.geometry.coordinates[0], e.result.geometry.coordinates[1]]}
    });
  },
  watch:{
    popupActivo(value){
      if(value){
        document.body.classList.add("modal-open");
      }
      else{
        document.body.classList.remove("modal-open");
      }
    }
  }
};
</script>
<style>
  body.modal-open {
    overflow: hidden;
  }
  .disabled-upload {
    display: none;
  }
  .con-img-upload {
    height: 50%;
  }
  .is-danger {
    border: 1px solid #ea5455 !important;
  }
  .is-true {
    border: 1px solid #28c76f !important;
  }
  .bg-color-done {
    background: #d4f4e2;
    color: #28c76f !important;
    border: 1px solid #28c76f !important;
    border-left: hidden !important;
  }

  .bg-color-error {
    background: #fbdddd;
    color: #ea5455 !important;
    border: 1px solid #ea5455 !important;
    border-left: hidden !important;
  }

  .topPlacement >.vdp-datepicker__calendar {
    bottom: 100%;
  }
  #removePopUp .vs-popup {
    width: 400px;
    height: auto;
  }

  
  .vs-tabs--content {
    padding: 0;
  }
  .vs-tabs--ul {
    box-shadow: none;
  }
  .con-vs-tabs {
    overflow: visible;
  }
  .con-vs-tabs .con-slot-tabs{
    overflow: visible;
    margin-top: 1rem;
  }
  .doctorDetails .vs-card--content {
    padding: 20px;
  }
  .doctorDetails .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
      padding: 0.75rem 1rem;
  }
  .doctorDetails .vs-input--label {
      display: block;
      margin-bottom: 0.3rem;
  }
  .doctorDetails .vs-input--input,
  .doctorDetails .vs-select--input,
  .doctorDetails .vdp-datepicker input {
    font-family: inherit;
  }
  .doctorDetails .vs-select--input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 9px 10px;
  }
  .doctorDetails .mapboxgl-ctrl-geocoder {
    width: 100%!important;
    max-width: unset!important;
    box-shadow: none;
  }
  .doctorDetails  .mapboxgl-ctrl-geocoder input {
    padding: 8px 10px 8px 30px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 1rem;
    height: 40px;
   }
  .doctorDetails .mapboxgl-ctrl-geocoder--icon-search {
    top: 10px;
    width: 20px;
    height: 20px;
  }
  .doctorDetails .mapboxgl-ctrl-geocoder--icon {
    fill: #cfcfcf;
  }
  .doctorDetails .mapboxgl-ctrl-geocoder .suggestions{
    z-index: 10;
    position: relative;
  }

  @media screen and (min-width: 992px) {
    .doctorDetails .mainHeader .vs-card--header {
      padding: 5px;
    }
  }
  
</style>
